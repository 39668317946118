import { createSlice } from '@reduxjs/toolkit';
import { logout } from './authenticationSlice';

const INITIAL_STATE = {
  branch: null,
  company: null,
  entity: null,
  lastLoggedInUserId: null,
  filters: {
    branches: [],
    roles: [],
  },
  searchTerm: '',
};

export const userSlice = createSlice({
  name: 'user',
  initialState: INITIAL_STATE,
  reducers: {
    setCompanyForAdminUser(state, action) {
      state.company = action.payload;

      // Reset filters upon changing the company
      state.filters = INITIAL_STATE.filters;
    },
    setLastLoggedInUserId(state, action) {
      state.lastLoggedInUserId = action.payload;
    },
    setFilters(state, action) {
      for (const key in action.payload) {
        const value = action.payload[key];
        state.filters[key] = value;
      }
    },
    setUser(state, action) {
      state.entity = action.payload;
    },
    setSearchTerm(state, action) {
      state.searchTerm = action.payload;
    },
  },
  extraReducers: {
    [logout.type]: (state) => {
      for (const key in INITIAL_STATE) {
        const value = INITIAL_STATE[key];

        state[key] = value;
      }
    },
  },
});

export const {
  setCompanyForAdminUser,
  setLastLoggedInUserId,
  setFilters,
  setActivationFilter,
  setAvailabilityFilter,
  setBranchesFilter,
  setRolesFilter,
  setChecklistsFilter,
  setUser,
  setSearchTerm,
} = userSlice.actions;
