import axios from 'axios';
import { API_URL } from 'helpers/config';
import store from 'redux/store';
import { logout as logoutAction } from 'redux/slices/authenticationSlice';
import { getBearerToken } from 'helpers/auth';

function logoutFromApp(error) {
  if (error?.response?.status === 401) {
    store.dispatch(logoutAction());
  }
}

export const http = axios.create({
  baseURL: API_URL,
  headers: {
    Authorization: getBearerToken(),
  },
});

http.interceptors.response.use(
  (response) => response,
  (error) => {
    logoutFromApp(error);

    return Promise.reject(error);
  }
);

export async function login(data) {
  const request = http.post('/login', data);

  request.then((response) => {
    http.defaults.headers.Authorization = `Bearer ${response.data.data.token}`;
  });

  return request;
}

export async function logout() {
  return http.post('/logout');
}
