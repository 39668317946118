export default class Base {
  static schema = {};
  static typeName = '';

  constructor(properties = {}) {
    this.constructor.obfuscateSchema();

    for (const key in properties) {
      const humanizedKey = this.constructor.obfuscatedSchema[key] || key;

      this[humanizedKey] = properties[key];
    }
  }

  static obfuscateSchema() {
    if (typeof this.obfuscatedSchema === 'undefined') {
      this.obfuscatedSchema = Object.entries(this.schema).reduce(
        (result, [key, value]) => ({
          ...result,
          [value]: key,
        }),
        {}
      );
    }

    return this.obfuscatedSchema;
  }
}
