import store from 'redux/store';
import * as Yup from 'yup';

export const AuthStates = {
  EMPTY_STATE: null,
  SUCCESS_STATE: 'SUCCESSFUL',
  FAILURE_STATE: 'FAILURE',
  WRONG_CREDENTIALS_STATE: 'WRONG_CREDENTIALS',
};

export const AuthResponses = {
  WRONG_CREDENTIALS: 0,
  LINK_EXPIRED: 2,
};

export const AuthValidationSchema = {
  email: Yup.string()
    .email('strings.login_emailError')
    .required('strings.login_emailError'),
  password: Yup.string().min(8).required('strings.login_password'),
  passwordConfirm: Yup.string()
    .oneOf([Yup.ref('password'), null], 'strings.setPassword_match')
    .required('strings.setPassword_passwordConfirm'),
};

export function getBearerToken() {
  const state = store.getState();
  return `Bearer ${state.authentication.token}`;
};
