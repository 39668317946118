import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_COMPANIES_PAGINATED } from 'graphql/queries/Companies';
import useFetchOptions from 'hooks/useFetchOptions';
import { GRAPHQL_ENDPOINT } from 'helpers/GraphQLConstants';
import { LIST_TYPE_COMPANIES } from 'helpers/GenericConstants';
import Company from 'entities/Company';

export default function useCompanies() {
  const { fetchOptions } = useFetchOptions(LIST_TYPE_COMPANIES);
  const { data, error } = useQuery(GET_COMPANIES_PAGINATED, {
    ...fetchOptions,
  });
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const queryData = data?.[GRAPHQL_ENDPOINT.getCompaniesPaginated];
    const edges = queryData?.edges;
    const pageInfo = queryData?.pageInfo;

    if (edges) {
      const companyList = edges.map(({ node }) => new Company(node));
      setCompanies(companyList);

      if (pageInfo.total === edges.length) {
        setLoading(false);
      }
    }
  }, [data]);

  return { companies, error, loading };
}
