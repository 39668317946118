import Asset from 'entities/Asset';

export const AssetStatusKeysAPI = {
  active: 'ACTIVE',
  inactive: 'INACTIVE',
  checked_in: 'CHECKED_IN',
  checked_out: 'CHECKED_OUT',
  in_service: 'IN_SERVICE',
  draft: 'DRAFT',
  check_in_draft: 'CHECKIN_DRAFT',
};

export const Availability = {
  DRAFT: { name: 'checkout_draft', value: 'DRAFT', id: 'cout_draft' },
  CHECKIN_DRAFT: { name: 'checkin_draft', value: 'CHECKIN_DRAFT', id: 'cin_draft' },
  CHECKED_OUT: { name: 'not_available', value: 'CHECKED_OUT', id: 'chkd_out' },
  CHECKED_IN: { name: 'available', value: 'CHECKED_IN', id: 'chkd_in' },
  IN_SERVICE: { name: 'maintenance', value: 'IN_SERVICE', id: 'mnc' },
};

export const Status = {
  activated: {
    name: 'activated',
    value: 'ACTIVE',
  },
  deactivated: {
    name: 'deactivated',
    value: 'INACTIVE',
  },
};

const filterByCompany = (asset, companyIds) => {
  return companyIds?.length === 0 || companyIds.includes(asset.company.id);
}

const filterByActivation = (asset, activations) => {
  return activations?.length === 0 || activations.includes(asset.status);
}

const filterByAvailability = (asset, availabilities) => {
  return availabilities?.length === 0 || availabilities.includes(asset.availability);
}

const filterByChecklist = (asset, checklists) => {
  return checklists?.length === 0 || checklists.includes(asset.checklist.id);
}

export function groupByBranchAndFilter(assets, filters) {
  const { companies, activation, availability, checklists } = filters;

  const branches = assets.reduce((branches, assetData) => {
    const asset = new Asset(assetData.node)

    const companyMatches = filterByCompany(asset, companies);
    const activationMatches = filterByActivation(asset, activation);
    const availabilityMatches = filterByAvailability(asset, availability);
    const checklistMatches = filterByChecklist(asset, checklists);
    const allMatches = companyMatches && activationMatches && availabilityMatches && checklistMatches;

    if (!allMatches) return branches;

    const branch = asset.branch;
    const hasBranch = Boolean(branch);
    const company = asset.company;
    const key = hasBranch ? branch.id : `${company.typeName}_${company.id}`;
    const branchWithData = branches[key] || { ...(branch || company), id: key, branch, company, assets: [] };

    const address = [branchWithData.streetAndNumber, branchWithData.postcode, branchWithData.city].filter(Boolean).join(' ');
    const hasAddress = Boolean(address);

    if (!hasAddress) return branches;

    branchWithData.assets.push(asset);

    branches[key] = branchWithData;

    return branches;
  }, {});

  return branches;
};
