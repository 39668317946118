import { withStyles } from '@material-ui/core/styles';
import { IconButton as MuiIconButton } from '@material-ui/core';

export const IconButton = withStyles((theme) => ({
  root: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}))(MuiIconButton);
