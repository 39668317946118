import Base from './Base';
import User from './User';
import Asset from './Asset';
import TransactionCustomerDetail from './TransactionCustomerDetail';
import TransactionCheckGroup from './TransactionCheckGroup';

const schema = {
  asset: 'a1',
  assetId: 'aU',
  availability: 'a',
  branch: 'b1',
  company: 'c1',
  createdAt: 'cA',
  customerName: 'cN',
  id: 'id',
  invoiceNumber: 'iN',
  lastGroupIndex: 'lGI',
  pickupAt: 'pA',
  protocolImage: 'pI',
  protocolSummary: 'pS',
  reportedAt: 'rA',
  transactionCheckGroups: 'tCG2',
  transactionCheckValues: 'tCV2',
  transactionCustomerDetail: 'tCD1',
  typeName: '__typename',
  updatedAt: 'uA',
  user: 'u1',
};

export default class AssetTransaction extends Base {
  static schema = schema;
  static typeName = 'AT';
  constructor(properties = {}) {
    super(properties);

    if (this.user) {
      this.user = new User(this.user);
    }

    if (this.transactionCustomerDetail) {
      this.transactionCustomerDetail = new TransactionCustomerDetail(
        this.transactionCustomerDetail
      );
    }

    if (this.transactionCheckGroups) {
      this.transactionCheckGroups = this.transactionCheckGroups?.map(
        (tcg) => new TransactionCheckGroup(tcg)
      );
    }

    if (this.asset) {
      this.asset = new Asset(this.asset);
    }
  }
}
