import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { FormattedMessage } from 'react-intl';

const options = [
  { value: true, label: 'Deutschlandweit' },
  { value: 25000, label: "25" },
  { value: 50000, label: "50" },
  { value: 75000, label: "75" },
  { value: 100000, label: "100" },
  { value: 150000, label: "150" },
];

function RadiusField({ onChange, value }) {
  const valueOption = options.find(option => option.value === value);

  const changeHandler = (event, option) => {
    onChange(event, option.value);
  }

  return (
    <Autocomplete
      value={valueOption}
      onChange={changeHandler}
      options={options}
      disableClearable
      getOptionLabel={(option) => option.label}
      getOptionSelected={(option, value) => option.value === value.value}
      renderInput={(params) => <TextField {...params} label={<FormattedMessage id="filter_radius" />} variant="outlined" />}
    />
  );
};

RadiusField.defaultProps = {
  onChange: () => null,
};

export default RadiusField;
