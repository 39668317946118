export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('availability-map-redux-state', serializedState);
  } catch {
    // We'll just ignore write errors
  }
};

export const loadState = () => {
  try {
    // TODO: needs a merger implementation for changes in the store
    const serializedState = localStorage.getItem('availability-map-redux-state');
    if (serializedState === null) {
      return undefined;
    }
    const state = JSON.parse(serializedState);
    return state;
  } catch (error) {
    return undefined;
  }
};
