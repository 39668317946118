import { useEffect, useState } from 'react';
import useCompanies from 'hooks/useCompanies';

export default function useChecklists() {
  const [loading, setLoading] = useState(true);
  const [checklists, setChecklists] = useState([]);
  const { companies, loading: companiesLoading } = useCompanies();

  useEffect(() => {
    if (!companiesLoading) {
      const checklistsData = companies.flatMap(company => company.checklists);
      setChecklists(checklistsData);

      setLoading(false);
    }
  }, [companies, companiesLoading]);

  return { loading, checklists };
}
