import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { NetworkStatus, useQuery } from '@apollo/client';
import { GET_ASSETS_FOR_LIST_VIEW } from 'graphql/queries/Assets';
import useFetchOptions from 'hooks/useFetchOptions';
import { LIST_TYPE_ASSETS } from 'helpers/GenericConstants';
import { assetListDataUpdated } from 'redux/slices/appSlice';
import { GRAPHQL_ENDPOINT } from 'helpers/GraphQLConstants';
import { groupByBranchAndFilter } from 'helpers/asset';

// returns assets grouped by their branch or company if no branch available
export default function useAssets(searchTerm, filters) {
  const { fetchOptions } = useFetchOptions(LIST_TYPE_ASSETS);
  const { data, loading: dataLoading, networkStatus } = useQuery(GET_ASSETS_FOR_LIST_VIEW, {
    ...fetchOptions,
  });
  const dispatch = useDispatch();
  const [assets, setAssets] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (networkStatus === NetworkStatus.loading) {
      dispatch(assetListDataUpdated(Date.now()));
    }
  }, [dispatch, networkStatus]);

  useEffect(() => {
    const queryData = data?.[GRAPHQL_ENDPOINT.getAssetsPaginated];
    const edges = queryData?.edges;
    const pageInfo = queryData?.pageInfo;

    if (edges && pageInfo?.total === edges?.length) {
      const result = groupByBranchAndFilter(edges, filters);
      setAssets(result);

      setLoading(false);
    }
   }, [data, dataLoading, searchTerm, filters])

  return { loading, assets };
}
