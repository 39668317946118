import PropTypes from 'prop-types';
import IconAvailable from 'assets/images/asset_available.svg';
import IconCheckinDraft from 'assets/images/asset_check_in_draft.svg';
import IconCheckoutDraft from 'assets/images/asset_check_out_draft.svg';
import IconDeactivated from 'assets/images/asset_deactivated.svg';
import IconMaintenance from 'assets/images/asset_maintenance.svg';
import IconUnavailable from 'assets/images/asset_unavailable.svg';
import { Image } from './style';

const availabilities = {
  DRAFT: { icon: IconCheckoutDraft, color: 'success' },
  CHECKIN_DRAFT: { icon: IconCheckinDraft, color: 'error' },
  CHECKED_OUT: { icon: IconUnavailable, color: 'error' },
  CHECKED_IN: { icon: IconAvailable, color: 'success' },
  IN_SERVICE: { icon: IconMaintenance, color: 'info' },
  INACTIVE: { icon: IconDeactivated, color: 'info' },
};

const AssetStatusIcon = ({ availability, deactivated, ...props }) => {
  if ((typeof availability === 'boolean' || !Boolean(availability)) && !Boolean(deactivated)) return null;

  const { icon } = deactivated ? availabilities.INACTIVE : availabilities[availability];

  return <Image {...props} src={icon} />;
};

AssetStatusIcon.propTypes = {
  availability: PropTypes.oneOf([...Object.keys(availabilities), true]).isRequired,
  deactivated: PropTypes.bool,
};

export default AssetStatusIcon;
