export const GRAPHQL_EDGE = {
  asset: 'AEdge',
  company: 'CEdge',
};

export const GRAPHQL_ENDPOINT = {
  getAsset: 'getA',
  getAssetsPaginated: 'getA2P',
  getCompany: 'getC',
  getCompaniesPaginated: 'getC2P',
};
