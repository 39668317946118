export const qrCodePaperShopLink = 'https://www.avery-zweckform.com/produkt/wetterfeste-etiketten-l4773rev-20'
export const COMPANY_ADMIN = 'COMPANY_ADMIN'
export const SUPPORT = 'SUPPORT'
export const TECHNICIAN = 'TECHNICIAN'
export const ERROR_DUPLICATE_ASSETNAME = 'Duplicate asset name'
export const ERROR_DUPLICATE_INVENTORY_NUMBER = 'Duplicate inventory no'
export const ALREADY_DELETED_CHECKLIST_MESSAGE = 'Machine category not available anymore. Please select another one.'
export const LOGOUT = 'logout'
export const UNAUTHENTICATED_CODE = 401
export const STATUS_INACTIVE = 'INACTIVE'
export const STATUS_ACTIVE = 'ACTIVE'
export const PLATFORM = 'Web'
export const LIST_TYPE_ASSETS = 'assets'
export const LIST_TYPE_COMPANIES = 'companies'
