import { styled, withStyles } from '@material-ui/core/styles';
import { Grid as MuiGrid, Paper } from '@material-ui/core';
import LoginBackground from 'assets/images/login_bg.png';
import LoginBackground2x from 'assets/images/login_bg2x.png';
import LoginBackground3x from 'assets/images/login_bg3x.png';

export const LogoImage = styled('img')(({ theme }) => ({
  width: 227.4,
  [theme.breakpoints.only('xs')]: {
    width: 189.5,
  },
}));

export const Wrapper = styled('div')(({ theme }) => ({
  height: '100vh',
  maxHeight: '100vh',
  width: '100vw',
  overflow: 'hidden',
  display: 'grid',
  gridTemplateColumns: '100%',
  gridTemplateRows: '1fr auto',
}));

export const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  backgroundSize: 'cover',
  objectFit: 'contain',
  [theme.breakpoints.up('sm')]: {
    backgroundImage: `url(${LoginBackground3x})`,
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    backgroundImage: `url(${LoginBackground2x})`,
    padding: 64,
  },
  [theme.breakpoints.only('xs')]: {
    padding: 40,
    backgroundImage: `url(${LoginBackground})`,
  },
}));

export const Grid = withStyles((theme) => ({
  root: {
    justifyContent: 'center',
    alignItems: 'center',
  },
}))(MuiGrid);

export const Content = withStyles((theme) => ({
  root: {
    width: '640px',
    padding: 64,
  },
}))(Paper);

export const Footer = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.extraDark,
    color: theme.palette.background.paper,
    justifyContent: 'space-between',
    fontSize: 16,
    lineHeight: 2.4,
    letterSpacing: 0.15,
    padding: '20px 40px',
    [theme.breakpoints.up('lg')]: {
      padding: '40px 278px 40px 274px',
    },
    [theme.breakpoints.down('md')]: {
      padding: '40px 198px 40px 193px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '30px 64px',
    },
    [theme.breakpoints.only('xs')]: {
      padding: '20px 40px',
      fontSize: 14,
      lineHeight: 2,
    },
  },
}))(MuiGrid);
