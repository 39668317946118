import Base from './Base';

const schema = {
  createdAt: 'cA',
  entityId: 'eI',
  entityUuid: 'eU',
  guid: 'g',
  guidType: 'gT',
  id: 'id',
  typeName: '__typename',
  updated_at: 'uA',
};

export default class GuidEntry extends Base {
  static schema = schema;
  static typeName = 'GE';
}
