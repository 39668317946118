const cache = {};

export const geocodeAddress = async (address) => new Promise((resolve, reject) => {
  const existingValue = cache[address];

  if (existingValue) return resolve(existingValue);

  const geocoder = new window.google.maps.Geocoder();
  geocoder.geocode({ address }, (results, status) => {
    if (status === "OK") {
      const [result] = results;
      cache[address] = result;
      return resolve(result);
    }

    console.log('address', address);
    console.log('results', results);
    console.log('status', status);

    reject(status);
  })
});