import { styled, withStyles } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import MuiTypography from '@material-ui/core/Typography';

export const Container = withStyles((theme) => ({
  root: {
    borderRadius: theme.spacing(1.5),
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    maxWidth: '100%',
  }
}))(Paper);

export const Content = styled('div')(({ theme }) => ({
  padding: theme.spacing(3, 2),
}));

export const Actions = styled('div')(({ theme }) => ({
  padding: theme.spacing(1, 2),
}));

export const Typography = withStyles({
  subtitle1: {
    fontWeight: 500,
    lineHeight: 1.25,
  }
})(MuiTypography);

export const Distance = styled('div')(({ theme }) => ({
  fontSize: theme.spacing(1.5),
  color: theme.palette.primary.main,
  fontWeight: 500,
  lineHeight: 1.67,
  marginBottom: theme.spacing(2),
}));

export const Checklists = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: 6,
  flexWrap: 'wrap',
  justifyContent: 'center',
  marginBottom: theme.spacing(2.5),
}));
