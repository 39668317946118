import { forwardRef } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import MachineItem from 'components/MachineItem/MachineItem';
import { CloseButton, Popover } from './style';

const MachineList = forwardRef(({ anchor, assets, onClose }, ref) => {
  return (
    <Popover
      ref={ref}
      open={true}
      anchorEl={anchor}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'left',
      }}
    >
      <CloseButton onClick={onClose}>
        <CloseIcon />
      </CloseButton>

      {assets.map(asset => (
        <MachineItem key={asset.id} data={asset} />
      ))}
    </Popover>
  );
});

export default MachineList;