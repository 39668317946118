import Base from './Base';
import Branch from './Branch';
import GuidEntry from './GuidEntry';
import AssetTransaction from './AssetTransaction';
import Checklist from './Checklist';
import Company from './Company';
import Image from './Image';

const AssetStatusColor = {};
const AssetStatusKeysAPI = {};
const strings = {};

const schema = {
  alAssetTransactions: 'aAT2',
  availability: 'a',
  branch: 'b1',
  branchId: 'bI',
  checklist: 'cL1',
  checklistId: 'cLI',
  clientId: 'clientId',
  company: 'c1',
  companyId: 'cI',
  createdAt: 'cA',
  firstGuidEntry: 'fGE1',
  guidEntries: 'gE2',
  id: 'id',
  image: 'i1',
  imageId: 'iI',
  inventoryNumber: 'iN',
  latestAssetTransaction: 'lAT1',
  pickupAt: 'pA',
  name: 'n',
  serialNumber: 'sN',
  status: 's',
  typeName: '__typename',
  updatedAt: 'uA',
};

export default class Asset extends Base {
  static schema = schema;
  static typeName = 'A';

  constructor(properties = {}) {
    super(properties);

    if (this.firstGuidEntry) {
      this.firstGuidEntry = new GuidEntry(this.firstGuidEntry);
    }

    if (this.latestAssetTransaction) {
      this.latestAssetTransaction = new AssetTransaction(
        this.latestAssetTransaction
      );
    }

    if (this.checklist) {
      this.checklist = new Checklist(this.checklist);
    }

    if (this.branch) {
      this.branch = new Branch(this.branch);
    }

    if (this.company) {
      this.company = new Company(this.company);
    }

    if (this.image) {
      this.image = new Image(this.image);
    }
  }

  // static bc sometimes it is passed an Asset, sometimes User
  static getMachineData(asset) {
    if (!asset) return null;

    const dataArray = {};
    if (asset.name !== '') {
      dataArray.machine_name = asset.name;
    }

    if (asset.checklist_name !== '') {
      dataArray.machine_category = asset.checklist_name;
    } else if (asset.check_list?.name !== '') {
      dataArray.machine_category = asset.check_list.name;
    }

    if (asset.inventory_number !== '') {
      dataArray.machine_inventory = asset.inventory_number;
    }

    const branchName = Branch.getBranchName(asset.branch);
    if (branchName !== '') {
      dataArray.machine_branch = branchName;
    }

    return dataArray;
  }

  getAssetStatusLabelAndColor() {
    if (this.status === AssetStatusKeysAPI.inactive) {
      return { label: strings.deactivated, color: AssetStatusColor.inactive };
    }

    switch (this.availability) {
      case AssetStatusKeysAPI.checked_in:
        return { label: strings.available, color: AssetStatusColor.checked_in };
      case AssetStatusKeysAPI.checked_out:
        return {
          label: strings.not_available,
          color: AssetStatusColor.checked_out,
        };
      case AssetStatusKeysAPI.in_service:
        return {
          label: strings.maintenance,
          color: AssetStatusColor.in_service,
        };
      case AssetStatusKeysAPI.draft:
        return { label: strings.checkout_draft, color: AssetStatusColor.draft };
      case AssetStatusKeysAPI.check_in_draft:
        return {
          label: strings.checkin_draft,
          color: AssetStatusColor.check_in_draft,
        };
      default:
        return { label: strings.available, color: AssetStatusColor.checked_in };
    }
  }

  static getRestrictedBranchString(count, totalCount, type) {
    if (count === totalCount) {
      return type && type === 'description' ? '' : strings.no_restrictions;
    } else if (count === 0) {
      return strings.no_access;
    } else if (count === 1) {
      return strings.restricted_access;
    } else {
      const returnString =
        type && type === 'description'
          ? strings.restricted_access + ' ' + strings.to + ' '
          : '';
      return returnString + (count + ' ' + strings.locations);
    }
  }

  isInActiveOrInService() {
    return (
      this.status === AssetStatusKeysAPI.inactive ||
      this.availability === AssetStatusKeysAPI.in_service
    );
  }
}
