import { useTheme, useMediaQuery } from '@material-ui/core';
import { LogoImage, Wrapper, Container, Grid, Content } from './style';
import CompanyLogo from 'assets/images/klickrent-logo.png';

const Logo = () => <LogoImage src={CompanyLogo} alt="" />;

const AuthLayout = ({ children }) => {
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Wrapper>
      <Container>
        {smDown && (
          <>
            <Logo />
            {children}
          </>
        )}
        {mdUp && (
          <Grid container>
            <Content>
              <Logo />
              {children}
            </Content>
          </Grid>
        )}
      </Container>
    </Wrapper>
  );
};

export default AuthLayout;
