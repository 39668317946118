import { styled, withStyles } from '@material-ui/styles';
import MuiListItemText from '@material-ui/core/ListItemText';

export const Wrapper = styled('div')({
  position: 'relative',
  width: '100%',
});

export const PredictionListWrapper = styled('div')({
  background: "white",
  borderRadius: 2,
  borderTop: "1px solid #d9d9d9",
  boxShadow: "0 2px 6px rgba(0,0,0,0.3)",
  left: 0,
  listStyle: "none",
  margin: 0,
  overflow: "hidden",
  padding: 0,
  position: "absolute",
  top: "100%",
  width: "100%",
  zIndex: 1000,
});

export const PredictionMainText = styled('span')(({ theme }) => ({
  paddingRight: 3,
  fontSize: 13,
  marginLeft: 5,
  color: theme.palette.common.black,
}));

export const PredictionSecondaryText = styled('span')(({ theme }) => ({
  color: theme.palette.grey['700'],
  fontSize: 11,
}));
export const PredictionMatchedText = styled('span')({
  fontWeight: 700,
});

export const ListItemText = withStyles({
  root: {
    color: '#c1c7c9',
    display: "flex",
    fontSize: 11,
    // lineHeight: "30px",
    overflow: "hidden",
    padding: "0 4px",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
  }
})(MuiListItemText);
