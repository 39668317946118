import { useState, useEffect, useCallback, useRef } from 'react';
import TextField from '@material-ui/core/TextField';
import { FormattedMessage } from 'react-intl';
import ClickOutside from 'components/ClickOutside/ClickOutside';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import {
  PredictionListWrapper,
  PredictionMainText,
  PredictionMatchedText,
  PredictionSecondaryText,
  ListItemText,
  Wrapper,
} from './style.js';

const renderMatchedPredictionText = (text, ranges) =>
  ranges.reduce((elements, range, index, givenRanges) => {
    const { length, offset } = range;

    const element = (
      <PredictionMatchedText
        key={`${text}-${offset}-${length}-start`}
      >
        {text.substring(offset, offset + length)}
      </PredictionMatchedText>
    );

    let newElements = [...elements, element];

    const nextRange = givenRanges[index + 1];
    const hasNextRange = !!nextRange;

    if (hasNextRange) {
      const { offset: nextRangeOffset } = nextRange;
      const currentEnd = offset + length;

      const nextElement = (
        <span key={`${text}-${currentEnd}-${nextRangeOffset}-${index}-next`}>
          {text.substring(currentEnd, nextRangeOffset)}
        </span>
      );
      newElements = [...newElements, nextElement];
    } else {
      const currentEnd = offset + length;
      const nextElement = (
        <span key={`${text}-${currentEnd}-end`}>
          {text.substring(currentEnd)}
        </span>
      );

      newElements = [...newElements, nextElement];
    }

    return newElements;
  }, []);

function Predictions({ data, onChange }) {
  const fakeElement = useRef(document.createElement('div'));

  if (data.length === 0) return null;

  const clickHandler = (prediction) => {
    const { google } = window;

    const service = new google.maps.places.PlacesService(fakeElement.current);
    const placeId = prediction['place_id'];

    // logging the address components for the prediction
    service.getDetails({ placeId }, place => {
      onChange({ place, prediction });
    });
  }

  return (
    <PredictionListWrapper>
      <List>
        {data.map(prediction => (
          <Prediction
            data={prediction}
            key={prediction.place_id}
            onClick={() => clickHandler(prediction)} />
        ))}
        <div>
          {/* <img src={poweredByGoogle} alt="Powered by Google" /> */}
        </div>
      </List>
    </PredictionListWrapper>
  );
}

function Prediction({ data, onClick }) {
  const emptyRanges = [{ offset: 0 }];
  const {
    structured_formatting: {
      main_text: mainText,
      main_text_matched_substrings: mainTextMatchRanges = emptyRanges,
      secondary_text: secondaryText,
      secondary_text_matched_substrings: secondaryTextMatchRanges = emptyRanges,
    },
    place_id: placeId,
  } = data;

  return (
    <ListItem
      button={true}
      key={placeId}
      onMouseDown={event => event.preventDefault()}
      onClick={onClick}>
      <ListItemIcon style={{ minWidth: 'auto', marginRight: 12 }}>
        <LocationOnIcon fontSize="small" />
      </ListItemIcon>
      <ListItemText>
        <PredictionMainText>
          {renderMatchedPredictionText(mainText, mainTextMatchRanges)}
        </PredictionMainText>
        {secondaryText && (
          <PredictionSecondaryText>
            {renderMatchedPredictionText(
              secondaryText,
              secondaryTextMatchRanges
            )}
          </PredictionSecondaryText>
        )}
      </ListItemText>
    </ListItem>
  );
}

function AddressInput({ onChange, onFocus, onBlur, ...restProps }) {
  const inputRef = useRef(null);
  const [value, setValue] = useState('');
  const [sessionToken, setSessionToken] = useState('');
  const [isFocused, setFocused] = useState(true);
  const [predictions, setPredictions] = useState([]);
  const locationLabel = (<FormattedMessage id="filter_location" />);

  useEffect(() => {
    const { google } = window;
    const sessionToken = new google.maps.places.AutocompleteSessionToken();

    setSessionToken(sessionToken);
  }, []);

  const fetchPredictions = useCallback((value) => {
    const { google } = window;

    if (!google) return;
    if (!value) return;

    const options = {
      types: ['geocode'],
      componentRestrictions: {
        country: ['at', 'de'],
      },
      input: value,
      sessionToken,
    };

    const service = new google.maps.places.AutocompleteService();
    service.getPlacePredictions(options, (predictions, status) => {
      if (status !== google.maps.places.PlacesServiceStatus.OK) { setPredictions([]); return; }

      setPredictions(predictions);
    });
  }, [sessionToken]);

  const handleClickOutside = () => { setFocused(false); };

  const changeHandler = (event) => {
    const newValue = event.target.value;

    fetchPredictions(newValue)

    setValue(newValue);
  };

  const predictionHandler = ({ place, prediction }) => {
    const { description } = prediction;

    setValue(description);
    onChange(place);

    inputRef.current?.blur();
  };

  return (
    <ClickOutside onClickOutside={handleClickOutside}>
      <Wrapper>
        <TextField
          value={value}
          label={locationLabel}
          variant="outlined"
          inputRef={inputRef}
          onChange={changeHandler}
          onFocus={(event) => { setFocused(true); onFocus?.(event); }}
          onBlur={(event) => { setFocused(false); onBlur(event); }}
          fullWidth
          {...restProps}
        />

        {isFocused && <Predictions data={predictions} onChange={predictionHandler} />}
      </Wrapper>
    </ClickOutside>
  )
}

export default AddressInput;
