import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash.isequal';
import Map from 'components/Map/Map';
import Filters from 'components/Filters/Filters';
import Sidebar from 'components/Sidebar/Sidebar';
import useAssets from 'hooks/useAssets';
import { geocodeAddress } from 'helpers/geocoder';
import rateLimiterFactory from 'helpers/rateLimiter';
import { setBranches } from 'redux/slices/geocoderSlice';

const rateLimit = rateLimiterFactory(1000, 2);

const INITIAL_FILTERS = {
  location: {
    lat: 51.0426137,
    lng: 10.6480087
  },
  radius: true,
  activation: ['ACTIVE'],
  availability: ['CHECKED_IN'],
  companies: [],
  checklists: [],
};

const { radius, location, ...INITIAL_SUBFILTERS } = INITIAL_FILTERS;

export default function MachinesOnMap() {
  const [filters, setFilters] = useState(INITIAL_FILTERS);
  const [subfilters, setSubfilters] = useState(INITIAL_SUBFILTERS);
  const cachedBranchCoords = useSelector(state => state.geocoder.branches);
  const dispatch = useDispatch();
  const { assets, loading: assetsLoading } = useAssets('', subfilters);
  const [loading, setLoading] = useState(true);
  const [points, setPoints] = useState([]);

  const filterHandler = useCallback((filters) => {
    setFilters(filters);

    const { radius, location, ...newSubfilters } = filters;

    setSubfilters((subfilters) => {
      if (isEqual(newSubfilters, subfilters) === false) {
        return newSubfilters;
      }

      return subfilters;
    });
  }, []);

  useEffect(() => {
    if (assetsLoading) { return; }

    async function placeMarkers() {
      const branchCoords = {};

      // prepare the new marker while considering the existing ones
      for (const branchId in assets) {
        const branch = assets[branchId];

        if (cachedBranchCoords[branchId]) continue;

        rateLimit(async () => {
          const branchId = branch.id;
          const address = [branch.streetAndNumber, branch.postcode, branch.city].filter(Boolean).join(' ');

          if (address) {
            try {
              const geocode = await geocodeAddress(address);

              const coords = { lat: geocode.geometry.location.lat(), lng: geocode.geometry.location.lng() };
              branchCoords[branchId] = coords;
            } catch (err) {
              console.error('err', err);
              // to avoid asking them again and again
              if (err !== 'OVER_QUERY_LIMIT') {
                branchCoords[branchId] = true;
              }
            } finally {
              return true;
            }
          }
        });
      }

      rateLimit(({ isLast }) => { setLoading(false); dispatch(setBranches(branchCoords)); })
    }

    placeMarkers();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assets, assetsLoading, dispatch]);

  useEffect(() => {
    if (loading) return;

    setPoints(Object.values(assets).map(point => ({
      ...point,
      location: cachedBranchCoords[point.id],
    })));
  }, [loading, assets, cachedBranchCoords]);

  if (loading) return null;

  return (
    <>
      <Sidebar>
        <Filters values={filters} onChange={filterHandler} />
      </Sidebar>

      <Map center={filters.location} points={points} radius={filters.radius} />
    </>
  )
};
