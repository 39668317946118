import { Container, ImageContainer, AvailabilityAndStatusIcon, Content, Typography } from './style';
import SmallMachineImage from 'components/SmallMachineImage/SmallMachineImage';

function MachineItem({ data }) {
  const { image, name, checklist } = data;

  return (
    <Container>
      <ImageContainer>
        <SmallMachineImage url={image?.smallUrl} name={name} />

        <AvailabilityAndStatusIcon
          availability={data.availability}
          deactivated={data.status === 'INACTIVE'}
        />
      </ImageContainer>

      <Content>
        <Typography variant="subtitle1">{checklist.name}</Typography>
        <Typography variant="subtitle2">{name}</Typography>

      </Content>
    </Container>
  );
}

export default MachineItem;

