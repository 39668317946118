/* global google */
import { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Container, MapContainer } from './style';
import MarkerIcon from 'components/MarkerIcon/MarkerIcon';
import InfoWindow from 'components/InfoWindow/InfoWindow';

const MAP_OPTIONS = {
  center: {
    lat: 51.0426137,
    lng: 10.6480087
  },
  zoom: 7,
  clickableIcons: false,
  streetViewControl: false,
  mapTypeControl: false,
  rotateControl: false,
  fullscreenControl: true,
};

function getSelectedPoint(points, selectedPointId) {
  if (selectedPointId === null) return;

  return points.find(({ id }) => selectedPointId === id)
}

const Map = ({ points, center, radius }) => {
  const [selectedBranchId, setSelectedBranchId] = useState(null);
  const centerMarker = useRef(null);
  const mapElement = useRef(null);
  const map = useRef(null);
  const markers = useRef(null);
  const distanceToCenter = selectedBranchId ? google.maps.geometry.spherical.computeDistanceBetween(
    markers.current?.[selectedBranchId]?.position,
    centerMarker.current?.position
  ) : null;

  const selectedBranch = getSelectedPoint(points, selectedBranchId);

  useEffect(() => {
    const newMarkers = {};

    // prepare the new marker while considering the existing ones
    for (const point of points) {
      const pointId = point.id;
      let marker = markers.current?.[pointId];
      delete markers.current?.[pointId];

      if (!marker) {
        if (point.location && point.location !== true) {
          marker = new google.maps.Marker({
            position: point.location,
            map: map.current,
            icon: MarkerIcon,
          });

          marker.addListener('click', function() { setSelectedBranchId(pointId); });
        }
      }

      if (marker) {
        newMarkers[pointId] = marker;
        marker.setMap(map.current);
      }
    }

    // clean up the unnecessary old markers
    for (const oldMarkerId in markers.current) {
      const oldMarker = markers.current[oldMarkerId];

      oldMarker.setMap(null);
    }

    markers.current = newMarkers;

    // clean up the unnecessary old markers
    for (const oldMarkerId in markers.current) {
      const oldMarker = markers.current[oldMarkerId];

      oldMarker.setMap(map.current);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [points]);

  useEffect(() => {
    map.current = new google.maps.Map(mapElement.current, MAP_OPTIONS);
  }, [])

  useEffect(() => {
    if (centerMarker.current) {
      centerMarker.current.setMap(null);
    }

    map.current.setCenter(center);
    map.current.setZoom(9);

    centerMarker.current = new google.maps.Marker({
      position: center,
      map: map.current,
      zIndex: 9999,
    });
  }, [center]);

  useEffect(() => {
    for (const id in markers.current) {
      const marker = markers.current[id];
      const markerPosition = marker.position;

      const distanceBetween = google.maps.geometry.spherical.computeDistanceBetween(centerMarker.current.position, markerPosition);

      if (radius !== true && distanceBetween > radius) {
        marker.setMap(null);
      } else {
        marker.setMap(map.current);
      }
    }

    // google.maps.geometry.spherical.computeDistanceBetween(b, a)
  }, [radius, center]);

  return (
    <Container>
      <MapContainer ref={mapElement} />
      {selectedBranchId !== null && (
        <InfoWindow
          branch={selectedBranch}
          distance={distanceToCenter}
          onClose={() => setSelectedBranchId(null)}
        />)
      }
    </Container>
  )
};

Map.propTypes = {
  radius: PropTypes.oneOfType([PropTypes.number, PropTypes.bool])
}


export default Map;
