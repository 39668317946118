import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import CLickOutside from 'components/ClickOutside/ClickOutside';
import Chip from 'components/Chip/Chip';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import MachineList from 'components/MachineList/MachineList';
import { Actions, Checklists, Container, Content, Distance, Typography } from './style';

function InfoWindow({ branch, distance, onClose }) {
  const [machineListElementRef, setMachineListElementRef] = useState(null);
  const showMachineListButtonRef = useRef(null);
  const [showMachineList, setMachineListVisible] = useState(false);
  const address = [branch.streetAndNumber, branch.postcode, branch.city].filter(Boolean).join(' ');
  const checklists = branch.assets.map(asset => asset.checklist.name);
  const uniqueChecklists = new Set(checklists);
  const uniqueChecklistsArray = Array.from(uniqueChecklists);
  const distanceInKm = Math.round(distance / 1000);

  const closeMachineList = () => { setMachineListVisible(false); };
  const openMachineList = () => { setMachineListVisible(true); };

  return (
    <CLickOutside onClickOutside={onClose} ignoreElement={machineListElementRef}>
      <Container elevation={2}>
        <Content>
          <Typography variant="subtitle1">
            {branch.company.name}
          </Typography>

          <Typography variant="subtitle1">
            {address}
          </Typography>

          <Distance>
            <FormattedMessage id="pin_distance" values={{ distance: distanceInKm }} />
          </Distance>

          <Checklists>
            {uniqueChecklistsArray.map((checklist) => (
              <Chip key={checklist} label={checklist} />
            ))}
          </Checklists>

          <Button
            ref={showMachineListButtonRef}
            onClick={openMachineList}
            variant="outlined"
            color="primary"
            fullWidth>
            <FormattedMessage id="show_machine_list" />
          </Button>
        </Content>

        <Divider />

        <Actions>
          <Button
            href={`mailto:${branch.company.email}`}
            color="primary"
            startIcon={<MailOutlineIcon fontSize="large" />}
            fullWidth>
            {branch.company.email}
          </Button>
        </Actions>
      </Container>

      {showMachineList && (<MachineList
        ref={setMachineListElementRef}
        assets={branch.assets}
        onClose={closeMachineList}
        anchor={showMachineListButtonRef.current}
        />)}
    </CLickOutside>
  );
}

InfoWindow.propTypes = {
  // The distance from the `branch` to the `center` in meters
  distance: PropTypes.number.isRequired,
}

export default InfoWindow;
