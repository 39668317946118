import { useSelector } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from 'components/Routes/Routes';
import GraphQlWrapper from 'components/GraphQlWrapper/GraphQlWrapper';

function App() {
  const isLoggedIn = useSelector((state) => state.authentication.isLoggedIn);

  const publicAppTree = (
    <Router>
      <Routes />
    </Router>
  )

  if (!isLoggedIn) {
    return publicAppTree;
  }

  return (
    <GraphQlWrapper>
      {publicAppTree}
    </GraphQlWrapper>
  );
}

export default App;
