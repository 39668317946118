import { gql } from '@apollo/client';

export const DEFAULT_ASSET_DATA = gql`
  fragment DefaultAssetData on A {
    a
    clientId @client
    id
    iN
    n
    sN
    s
    uA
    i1 {
      id
      fN
      sU
      u
    }
    b1 {
      c
      e
      cC
      id
      n
      sN
      p
    }
    c1 {
      id
      n
      c
      e
      iN
      sAN
      p
      a
    }
    cL1 {
      id
      n
      c1 {
        id
      }
    }
    fGE1 {
      eI
      eU
      g
      gT
      id
    }
  }
`;
