import Base from './Base';
import TransactionCheckValue from './TransactionCheckValue';

const schema = {
  assetTransaction: 'aT1',
  checkGroup: 'cG1',
  checkGroupName: 'cGN',
  createdAt: 'cA',
  id: 'id',
  transactionCheckValues: 'tCV2',
  typeName: '__typename',
  updatedAt: 'uA',
};

export default class TransactionCheckGroup extends Base {
  static schema = schema;
  static typeName = 'TCG';

  constructor(properties = {}) {
    super(properties);
    this.transactionCheckValues = this.transactionCheckValues?.map(
      (tcv) => new TransactionCheckValue(tcv)
    );
  }
}
