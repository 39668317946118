import { NetworkStatus, useQuery } from '@apollo/client';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { GET_COMPANIES_PAGINATED } from 'graphql/queries/Companies';
import { companyListDataUpdated } from '../redux/slices/appSlice';
import useFetchOptions from './useFetchOptions';
import { LIST_TYPE_COMPANIES } from 'helpers/GenericConstants';

export function useCompanyListData() {
  const dispatch = useDispatch();
  const { fetchOptions } = useFetchOptions(LIST_TYPE_COMPANIES);
  const {
    data,
    error,
    fetchMore,
    loading,
    networkStatus,
  } = useQuery(GET_COMPANIES_PAGINATED, { ...fetchOptions });

  useEffect(() => {
    if (networkStatus === NetworkStatus.loading) {
      dispatch(companyListDataUpdated(Date.now()));
    }
  }, [dispatch, networkStatus]);

  if (data && data.getC2P.pageInfo.hasNextPage) {
    fetchMore({
      variables: {
        after: data.getC2P.pageInfo.endCursor,
      },
    });
  }

  return { error, loading };
}
