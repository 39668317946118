const MachineIcon = (props) => (
  <svg width={22} height={15} viewBox="0 0 22 15" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M23-5H-1v24h24z" />
      <g fill="#ffffff">
        <path d="M8.058 3.936H6.972V6H2V1h5.515L10 6H8.058z" />
        <path d="M4.438 14.14c1.058 0 1.917-.884 1.917-1.974 0-1.09-.86-1.972-1.917-1.972-1.06 0-1.919.882-1.919 1.972 0 1.09.86 1.975 1.919 1.975zm6.745 0c1.059 0 1.917-.884 1.917-1.974 0-1.09-.858-1.972-1.917-1.972-1.06 0-1.917.882-1.917 1.972 0 1.09.858 1.975 1.917 1.975zM0 12.063l2.044-4.68h12.21l.543 1.552.966-1.789a4.422 4.422 0 0 1-.68-1.088c-.927-2.187-.029-4.733 2.047-5.795L17.64 0l3.232 7.126.744-.28L22 7.923l-1.65.622-.006-.022a4.158 4.158 0 0 1-1.272.224 4.22 4.22 0 0 1-1.593-.313 4.25 4.25 0 0 1-.873-.489l-1.309 2.421.593 1.696h-1.964c.001.035.01.069.01.104C13.936 13.73 12.7 15 11.183 15c-1.518 0-2.754-1.27-2.754-2.834 0-.035.01-.069.012-.104H7.18c.001.035.01.069.01.104C7.19 13.73 5.954 15 4.437 15c-1.518 0-2.754-1.27-2.754-2.834 0-.035.01-.069.011-.104H0z" />
      </g>
    </g>
  </svg>
);

export default MachineIcon;
