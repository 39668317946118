import React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from 'styles/theme';
import App from 'components/App/App';
import StoreProvider from 'components/StoreProvider/StoreProvider';
import IntlProvider from 'components/IntlProvider/IntlProvider';
import ApolloProvider from 'components/ApolloProvider/ApolloProvider';
import clientResolver from 'graphql/client';

clientResolver().then(client => {
  ReactDOM.render(
    <ApolloProvider client={client}>
      <StoreProvider>
        <IntlProvider>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <App />
          </ThemeProvider>
        </IntlProvider>
      </StoreProvider>
    </ApolloProvider>,
    document.getElementById('root')
  )
});
