import { useEffect } from 'react';
import { NetworkStatus, useQuery } from '@apollo/client';
import { useDispatch } from 'react-redux';

import { GET_ASSETS_FOR_LIST_VIEW } from 'graphql/queries/Assets';
import { assetListDataUpdated } from 'redux/slices/appSlice';
import useFetchOptions from './useFetchOptions';
import { LIST_TYPE_ASSETS } from 'helpers/GenericConstants';
import { GRAPHQL_ENDPOINT } from 'helpers/GraphQLConstants';

export function useAssetListData() {
  const dispatch = useDispatch();
  const { fetchOptions } = useFetchOptions(LIST_TYPE_ASSETS);
  const {
    data,
    error,
    fetchMore,
    loading,
    networkStatus,
  } = useQuery(GET_ASSETS_FOR_LIST_VIEW, { ...fetchOptions });

  useEffect(() => {
    if (networkStatus === NetworkStatus.loading) {
      dispatch(assetListDataUpdated(Date.now()));
    }
  }, [dispatch, networkStatus]);

  if (data?.[GRAPHQL_ENDPOINT.getAssetsPaginated].pageInfo.hasNextPage) {
    fetchMore({
      variables: {
        after: data[GRAPHQL_ENDPOINT.getAssetsPaginated].pageInfo.endCursor,
      },
    });
  }

  return { error, loading };
}
