import { COMPANY_ADMIN, SUPPORT, TECHNICIAN } from 'helpers/userTypes';
import Base from './Base';

const strings = {};

const schema = {
  assetTransaction: 'aT1',
  branch: 'b1',
  createdAt: 'cA',
  email: 'e',
  firstName: 'fN',
  fullName: 'fuN',
  id: 'id',
  lastName: 'lN',
  newsLetter: 'nL',
  phone: 'p',
  role: 'r',
  salutation: 's',
  status: 'st',
  termsOfService: 'tOS',
  title: 't',
  typeName: '__typename',
  updatedAt: 'uA',
};

export default class User extends Base {
  static schema = schema;
  static typeName = 'U';
  static getFullName(firstName, lastName) {
    return `${firstName} ${lastName}`;
  }
  static getInitials(firstName, lastName) {
    if (firstName) {
      return `${firstName.charAt(0)}${lastName.charAt(0)}`;
    }
    return `${lastName.charAt(0)}${lastName.charAt(1)}`;
  }
  static getProfileInitials(firstName, lastName) {
    if (firstName) {
      return firstName.charAt(0);
    }
    return lastName.charAt(0);
  }
  static getProfileName(firstName, lastName) {
    if (firstName) {
      return `${firstName.charAt(0)}. ${lastName}`;
    }
    return lastName;
  }
  static getUserRoleList(userRole) {
    const list = [
      { id: 1, name: strings.user_role_technician, value: TECHNICIAN },
      { id: 2, name: strings.user_role_company_admin, value: COMPANY_ADMIN },
    ];
    if (userRole === SUPPORT) {
      list.push({ id: 3, name: strings.user_role_support, value: SUPPORT });
    }
    return list;
  }
}
