import { useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import AddressInput from 'components/AddressInput/AddressInput';
import RadiusField from 'components/RadiusField/RadiusField';
import CategoryField from 'components/CategoryField/CategoryField';
import CompanyField from 'components/CompanyField/CompanyField';
import StatusField from 'components/StatusField/StatusField';
import AvailabilityField from 'components/AvailabilityField/AvailabilityField';
import { Grid } from './style';

const validationSchema = Yup.object().shape({
  location: Yup.object().shape({ lat: Yup.number().required(), lng: Yup.number().required() }).required(),
})

function Filters({ onChange, values }) {
  const formik = useFormik({
    initialValues: values,
    validationSchema: validationSchema,
    validateOnBlur: true,
    validateOnMount: true,
    onSubmit: async (values) => {
      onChange(values);
    },
  })

  const locationHandler = (place) => {
    const latLng = {
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
    };

    formik.setFieldValue('location', latLng);
  }

  const radiusHandler = (event, selectedOption) => {
    formik.setFieldValue('radius', selectedOption);
  }

  const categoryHandler = (event, selectedOptions) => {
    formik.setFieldValue('checklists', selectedOptions);
  }

  const companyHandler = (event, selectedOptions) => {
    formik.setFieldValue('companies', selectedOptions);
  }

  const statusHandler = (event, selectedOptions) => {
    formik.setFieldValue('activation', selectedOptions);
  }

  const availabilityHandler = (event, selectedOptions) => {
    formik.setFieldValue('availability', selectedOptions);
  }

  const handleFocus = (event) => {
    formik.setFieldTouched(event.target.name, false);
  }

  useEffect(() => {
    onChange(formik.values);
  }, [onChange, formik.values]);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Grid>
          <AddressInput
            name="location"
            onBlur={formik.handleBlur}
            onFocus={handleFocus}
            onChange={locationHandler}
            error={formik.touched.location && Boolean(formik.errors.location)}
            helperText={formik.touched.location && [formik.errors.location?.lat, formik.errors.location?.lng].filter(Boolean).join('. ')}
          />

          <RadiusField value={formik.values.radius} onChange={radiusHandler} />

          <CategoryField value={formik.values.checklists} onChange={categoryHandler} />

          <CompanyField value={formik.values.companies} onChange={companyHandler} />

          <AvailabilityField value={formik.values.availability} onChange={availabilityHandler} />

          <StatusField value={formik.values.activation} onChange={statusHandler} />
        </Grid>
      </form>
    </>
  )
}

export default Filters;
