import Base from './Base';
import CheckGroup from './CheckGroup';

const schema = {
  checkGroups: 'cG2',
  company: 'c1',
  createdAt: 'cA',
  id: 'id',
  name: 'n',
  status: 's',
  typeName: '__typename',
  updatedAt: 'uA',
  pickupAt: 'pickup_at',
};

export default class Checklist extends Base {
  static schema = schema;
  static typeName = 'CL';

  constructor(properties = {}) {
    super(properties);
    this.checkGroups = this.checkGroups?.map(
      (checkGroup) => new CheckGroup(checkGroup)
    );
  }
}
