import { styled } from '@material-ui/styles';
import AssetStatusIcon from 'components/AssetStatusIcon/AssetStatusIcon';

export const OptionIcon = styled(AssetStatusIcon)(({ theme }) => ({
  marginRight: theme.spacing(1),
}));

export const ChipIcon = styled(AssetStatusIcon)({});

export const Option = styled('div')(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  [OptionIcon]: {
  }
}));
