import PropTypes from 'prop-types';

const FormIcon = ({ icon: Icon, isTouched, errors, ...props }) => {
  return (
    <Icon
      {...props}
      color={isTouched ? (errors ? 'error' : 'primary') : 'action'}
    />
  );
};

FormIcon.propTypes = {
  icon: PropTypes.object.isRequired,
  isTouched: PropTypes.bool,
  errors: PropTypes.string,
};

export default FormIcon;
