import { styled } from '@material-ui/core/styles';

export const BrandText = styled('div')(({ theme }) => ({
  color: theme.palette.common.white,
  wordWrap: 'break',
  margin:' 6px 0 8px 6px',
  fontSize: 14,
  fontWeight: 500,
  letterSpacing: 0.15,
  lineHeight: 'normal',
}));

export const Wrapper = styled('div')({
  display: 'flex',
});
