import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#0171b7',
      dark: '#015295',
      light: '#b2e3fa',
      extraDark: '#00284a',
    },
    secondary: {
      main: '#fbc02d',
      dark: '#f9a925',
      light: '#f9e635',
    },
    info: {
      main: '#546e7a',
      dark: '#37474f',
      light: '#b0bec5',
    },
    success: {
      main: '#4caf51',
      dark: '#2e7d33',
      light: '#a5d6a7',
    },
    warning: {
      main: '#ffff00',
      dark: '#ffd100',
      light: '#ffff00',
    },
    error: {
      main: '#e51321',
      dark: '#ca0007',
      light: '#f56e6d',
    },
  },
});

const baseTheme = createMuiTheme(theme, {
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          '@media(hover: none)': {
            position: 'fixed',
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
          },
        },
        "#root": {
          display: 'flex',
          minHeight: '100vh',
          height: '100%'
        }
      },
    },
    MuiTabs: {
      root: {
        color: 'white',
      },
      indicator: {
        backgroundColor: 'white',
      },
    },
    MuiTab: {
      root: {
        fontSize: '12px',
        textTransform: 'uppercase',
        '&$selected': {
          color: 'white',
        },
        [theme.breakpoints.up('sm')]: {
          minWidth: '120px',
        },
      },
    },
  },
});

export const darkTheme = createMuiTheme(baseTheme, {
  palette: {
    type: 'dark',
  },
});

export const lightTheme = createMuiTheme(baseTheme, {
  palette: {
    type: 'light',
  },
});

export default lightTheme;
