import MachineIcon from 'components/MachineIcon/MachineIcon';
import { Container } from './style';

function SmallMachineImage({ url, name }) {
  if (!url) {
    return (
      <Container>
        <MachineIcon />
      </Container>
    )
  }

  return (
    <img src={url} width="71" height="71" alt={name} />
  )
}

export default SmallMachineImage;
