import Base from './Base';
import CheckItem from './CheckItem';

const schema = {
  checkItems: 'cI2',
  checkList: 'cL1',
  createdAt: 'cA',
  id: 'id',
  name: 'n',
  sequenceNumber: 'sN',
  transactionCheckGroups: 'tCG2',
  typeName: '__typename',
  updatedAt: 'uA',
};

export default class CheckGroup extends Base {
  static schema = schema;
  static typeName = 'CG';

  constructor(properties = {}) {
    super(properties);
    this.checkItems = this.checkItems.map(
      (checkItem) => new CheckItem(checkItem)
    );
  }
}
