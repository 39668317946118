import { styled, withStyles } from '@material-ui/styles';
import MuiButton from '@material-ui/core/Button';

export const Button = withStyles({
  root: {
    width: '100%',
  }
})(MuiButton);


export const Grid = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
});
