import { IntlProvider as BaseIntlProvider } from 'react-intl';
import germanMessages from 'locales/de.json';

const IntlProvider = ({ children }) => {
  return (
    <BaseIntlProvider locale={navigator.language} defaultLocale="de" messages={germanMessages}>
      {children}
    </BaseIntlProvider>
  );
};

export default IntlProvider;
