import { styled } from '@material-ui/core/styles';

export const Container = styled('div')({
  position: 'relative',
  display: 'flex',
  flex: 1,
});

export const MapContainer = styled('div')({
  display: 'flex',
  flex: 1,
})