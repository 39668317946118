import Base from './Base';
import GuidEntry from './GuidEntry';
import { IMAGE_URL_PREFIX } from 'helpers/config';

const schema = {
  asset: 'a1',
  checkValueImages: 'cVI2',
  createdAt: 'cA',
  fileName: 'fN',
  guid: 'g',
  id: 'id',
  isDeleted: 'iD',
  latestCheckValueImage: 'lCVI1',
  smallUrl: 'sU',
  typeName: '__typename',
  updatedAt: 'uA',
  url: 'u',
};

export default class Image extends Base {
  static schema = schema;
  static typeName = 'I';

  get guid() {
    return this.firstGuidEntry?.[GuidEntry.schema.guid];
  }

  get hasImage() {
    return !!this.fileName;
  }

  get originalImageSource() {
    return this.getImageSourceWithSize('original');
  }

  get smallImageSource() {
    return this.getImageSourceWithSize('small');
  }

  getImageSourceWithSize(size) {
    const imageSizes = {
      original: 'original-image',
      small: 'small-image',
    };

    if (!this.hasImage() || !imageSizes[size]) {
      return '';
    }

    return `${IMAGE_URL_PREFIX}/${imageSizes[size]}/${this.fileName}`;
  }
}
