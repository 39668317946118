import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  LIST_TYPE_ASSETS,
  LIST_TYPE_COMPANIES,
} from 'helpers/GenericConstants';

// milliseconds (1 second) * seconds (1 minute) * minutes (1 hour) * hours (24 hours) * days (2 days)
const INTERVAL = 1000 * 60 * 60 * 24 * 2;

const getUpdatedAtByListType = (state, listType) => {
  switch (listType) {
    case LIST_TYPE_ASSETS:
      return state.app.assetListDataUpdatedAt;
    case LIST_TYPE_COMPANIES:
      return state.app.companyListDataUpdated;
    default:
      return null;
  }
};

export default function useFetchOptions(listType) {
  const updatedAt = useSelector((state) =>
    getUpdatedAtByListType(state, listType)
  );
  const [fetchOptions, setFetchOptions] = useState({
    fetchPolicy: 'cache-first',
  });

  useEffect(() => {
    if (updatedAt && Date.now() - updatedAt > INTERVAL) {
      setFetchOptions({
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
      });
    }
  }, [updatedAt]);

  return { fetchOptions };
}
