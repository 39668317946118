import { styled } from '@material-ui/styles';

export const Container = styled('div')({
  background: '#d8d8d8',
  width: 71,
  height: 71,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});
