import { styled } from '@material-ui/core/styles';

const AuthContainer = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    paddingTop: 64,
  },
  [theme.breakpoints.down('sm')]: {
    paddingTop: 130,
  },
  [theme.breakpoints.only('xs')]: {
    paddingTop: 64,
  },
}));

export default AuthContainer;
