import { Route, Switch } from 'react-router-dom';
import { LOGIN_PATH, MAP_PATH } from 'helpers/paths';
import ProtectedRoute from 'components/ProtectedRoute/ProtectedRoute';
import Login from 'components/Login/Login';
import MachinesOnMap from 'components/MachinesOnMap/MachinesOnMap';

export default function Routes() {
  return (
    <Switch>
      <ProtectedRoute exact path={MAP_PATH} component={MachinesOnMap} />
      <Route path={LOGIN_PATH} component={Login} />
    </Switch>
  );
}
