import { styled, withStyles } from '@material-ui/core/styles';
import MuiDrawer from '@material-ui/core/Drawer';

const drawerWidth = 318;

export const Drawer = withStyles({
  root: {
    width: drawerWidth,
    flexShrink: 0,
  },
  paper: {
    width: drawerWidth
  },
  paperAnchorDockedLeft: {
    borderRight: 0,
  }
})(MuiDrawer);

export const Header = styled('div')({
  background: '#0d548c',
  padding: '10px 12px'
});

export const Content = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
}));
