import { setContext } from '@apollo/client/link/context';
import { getBearerToken } from 'helpers/auth';

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      Authorization: getBearerToken(),
    },
  };
});

export default authLink;
