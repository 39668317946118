import Base from './Base';

const schema = {
  checkGroup: 'cG1',
  createdAt: 'cA',
  id: 'id',
  isMandatory: 'iM',
  keepValue: 'kV',
  name: 'n',
  sequenceNumber: 'sN',
  transactionCheckValues: 'tCV2',
  type: 't',
  typeName: '__typename',
  updatedAt: 'uA',
};

export default class CheckItem extends Base {
  static schema = schema;
  static typeName = 'CI';
}
