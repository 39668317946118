import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  TextField as MuiTextField,
  Typography as MuiTypography,
  FormControlLabel as MuiFormControlLabel,
} from '@material-ui/core';

export const LoginHead = withStyles((theme) => ({
  h6: {
    marginBottom: 32,
    [theme.breakpoints.only('xs')]: {
      marginBottom: 26,
    },
  },
}))(MuiTypography);

export const TextField = withStyles(() => ({
  root: {
    width: '100%',
    '& input': {
      boxShadow: '0 0 0px 1000px white inset',
      WebkitBoxShadow: '0 0 0 1000px white inset',
    },
  },
}))(MuiTextField);

export const PasswordField = withStyles(() => ({
  root: {
    marginTop: 16,
    marginBottom: 16,
  },
}))(TextField);

export const FormControlLabel = withStyles((theme) => ({
  root: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
}))(MuiFormControlLabel);

export const LoginButton = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.secondary.main,
    width: '100%',
    height: 42,
    marginTop: 64,
    marginBottom: 16,
    '&:hover': {
      background: theme.palette.secondary.main,
    },
  },
}))(Button);
