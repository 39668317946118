import React from 'react';
import { FormattedMessage } from 'react-intl';
import TextField from '@material-ui/core/TextField';
import Autocomplete from 'components/Autocomplete/Autocomplete';
import useChecklists from 'hooks/useChecklists';

const allCategoriesOption = { value: true, label: 'Alle Kategorien', };

function getOptionsByValues(options, values) {
  if (values.length === 0) {
    return [allCategoriesOption];
  }

  return values
    .filter(option => option.value !== true)
    .map(value => options.find(option => option.value === value));
}

function CategoryField({ onChange, value }) {
  const { checklists, loading } = useChecklists();
  const options = checklists.map(company => ({
    value: company.id,
    label: company.name,
  }));
  const valueOptions = getOptionsByValues(options, value);

  const changeHandler = (event, selectedOptions) => {
    onChange(event, selectedOptions.filter(option => option.value !== true).map(option => option.value))
  }

  return (
    <Autocomplete
      value={valueOptions}
      onChange={changeHandler}
      options={options}
      multiple
      getOptionLabel={(option) => option.label}
      getOptionSelected={(option, value) => option.value === value.value}
      loading={loading}
      renderInput={(params) => <TextField {...params} label={<FormattedMessage id="filter_type" />} InputLabelProps={{ shrink: true }} variant="outlined" />}
    />
  );
};

CategoryField.defaultProps = {
  onChange: () => null,
};

export default CategoryField;
