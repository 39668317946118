import { Route, Redirect, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { LOGIN_PATH } from 'helpers/paths';

const ProtectedRoute = ({ component: Component, ...restProps }) => {
  const location = useLocation();
  const isLoggedIn = useSelector((state) => state.authentication.isLoggedIn);

  if (!isLoggedIn) {
    return (
      <Redirect
        push
        to={{
          pathname: LOGIN_PATH,
          state: {
            from: location,
          },
        }}
      />
    );
  }

  return <Route {...restProps} component={Component} />;
};

export default ProtectedRoute;
