import Base from './Base';
import Branch from './Branch';
import Checklist from './Checklist';

const schema = {
  address: 'a',
  allowCustomerData: 'aCD',
  accessibleActiveAssetCount: 'aAAC',
  branchCount: 'aBC',
  branches: 'b2',
  checklists: 'cL2',
  city: 'c',
  companyLogo: 'cL',
  createdAt: 'cA',
  currentInvoiceNumber: 'cIN',
  email: 'e',
  id: 'id',
  invoiceName: 'iN',
  name: 'n',
  phone: 'p',
  postcode: 'p',
  protocolBackgroundColor: 'pBC',
  protocolTextColor: 'pTC',
  provideInvoice: 'pI',
  sendEmailToUser: 'sETU',
  status: 's',
  streetAndNumber: 'sAN',
  typeName: '__typename',
  updatedAt: 'uA',
};

export default class Company extends Base {
  static schema = schema;
  static typeName = 'C';

  constructor(properties = {}) {
    super(properties);

    if (this.branches) {
      this.branches = this.branches.map((branch) => new Branch(branch));
    }

    if (this.checklists) {
      this.checklists = this.checklists.map(checklist => new Checklist(checklist));
    }
  }

  hasBranches() {
    return this.branches?.length > 0;
  }

  hasChecklists() {
    return this.checklists?.length > 0;
  }
  static getFirstChar(name) {
    return name.charAt(0);
  }
}
