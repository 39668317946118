import { gql } from '@apollo/client';
import { DEFAULT_ASSET_DATA } from '../fragments/Assets';

export const GET_ASSETS_FOR_LIST_VIEW = gql`
  query GetAssetsPaginated($after: String) {
    getA2P(after: $after, first: 300) @connection(key: "getA2P") {
      edges {
        node {
          ...DefaultAssetData
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
        total
      }
    }
  }
  ${DEFAULT_ASSET_DATA}
`;
