import Base from './Base';

const schema = {
  assetTransaction: 'aT1',
  company: 'c',
  constructionSite: 'cS',
  createdAt: 'cA',
  id: 'id',
  name: 'n',
  phone: 'p',
  typeName: '__typename',
  updatedAt: 'uA',
};

export default class TransactionCustomerDetail extends Base {
  static schema = schema;
  static typeName = 'TCD';
}
