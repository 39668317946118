import Base from './Base';
import CheckValueImage from './CheckValueImage';

const schema = {
  assetTransaction: 'aT1',
  checkItem: 'cI1',
  checkItemName: 'cIN',
  checkValueImages: 'cVI2',
  comment: 'c',
  createdAt: 'cA',
  id: 'id',
  isNew: 'iN',
  reportedAt: 'rA',
  transactionCheckGroup: 'tCG1',
  type: 't',
  typeName: '__typename',
  updatedAt: 'uA',
  value: 'v',
};

export default class TransactionCheckValue extends Base {
  static schema = schema;
  static typeName = 'TCV';

  constructor(properties = {}) {
    super(properties);
    this.checkValueImages = this.checkValueImages?.map(
      (tcv) => new CheckValueImage(tcv)
    );
  }

  getCheckValueImages() {
    const images = [];

    if (this.hasCheckValueImages()) {
      this.checkValueImages.forEach((checkValueImage) => {
        images.push(new CheckValueImage(checkValueImage));
      });
    }

    return images;
  }

  getFirstCheckValueImage() {
    return this.hasCheckValueImages()
      ? new CheckValueImage(this.checkValueImages[0])
      : null;
  }

  hasCheckValueImages() {
    return (
      !!this.checkValueImages &&
      Array.isArray(this.checkValueImages) &&
      this.checkValueImages.length > 0
    );
  }
}
