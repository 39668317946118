import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import useFormatMessage from 'hooks/useFormatMessage';
import { login } from 'helpers/api';
import { loggedIn, setToken } from 'redux/slices/authenticationSlice';
import { setUser } from 'redux/slices/userSlice';
import { InputAdornment, Checkbox } from '@material-ui/core';
import { AuthStates, AuthValidationSchema } from 'helpers/auth';
import EmailIcon from '@material-ui/icons/MailOutline';
import KeyIcon from '@material-ui/icons/VpnKeyOutlined';
import InfoDialog from 'components/InfoDialog/InfoDialog';
import AuthLayout from 'components/AuthLayout/AuthLayout';
import AuthContainer from 'components/AuthContainer/AuthContainer';
import FormIcon from 'components/FormIcon/FormIcon';
import * as Yup from 'yup';
import {
  LoginHead,
  TextField,
  PasswordField,
  FormControlLabel,
  LoginButton,
} from './style';

const { EMPTY_STATE, WRONG_CREDENTIALS_STATE, FAILURE_STATE } = AuthStates;
const { email, password } = AuthValidationSchema;

const validationSchema = Yup.object().shape({
  email,
  password,
});

const Login = () => {
  const formatMessage = useFormatMessage();
  const history = useHistory();
  const dispatch = useDispatch();

  const [showPassword, setShowPassword] = useState(false);
  const [responseState, setResponseState] = useState(EMPTY_STATE);
  const isLoggedIn = useSelector((state) => state.authentication.isLoggedIn);

  useEffect(() => {
    if (isLoggedIn) {
      history.push('/');
    }
  }, [history, isLoggedIn]);

  const handleLogin = async (values) => {
    try {
      const response = await login(values);
      const token = response?.data?.data?.token;
      const user = response?.data?.data?.user;
      const role = user?.role;
      const isSupport = role === 'SUPPORT';

      if (isSupport) {
        dispatch(setToken(token));
        dispatch(setUser(user));

        if (token) {
          dispatch(loggedIn());
        }
      } else {
        throw new Error('The user does not have a support account.')
      }
    } catch (error) {
      if (error.response?.status === 401) {
        setResponseState(WRONG_CREDENTIALS_STATE);
      } else {
        setResponseState(FAILURE_STATE);
      }
    }
  };

  const closeHandler = () => {
    setResponseState(EMPTY_STATE);
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: handleLogin,
  });

  return (
    <AuthLayout>
      <AuthContainer>
        <form onSubmit={formik.handleSubmit}>
          <LoginHead variant="h6">{formatMessage('login_headline')}</LoginHead>
          <TextField
            name="email"
            variant="outlined"
            value={formik.values.email}
            label={
              formik.touched.email
                ? formik.errors.email || formatMessage('login_email')
                : null
            }
            InputLabelProps={{ shrink: true }}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            placeholder={formatMessage('login_email')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <FormIcon
                    icon={EmailIcon}
                    aria-label="email"
                    isTouched={formik.touched.email}
                    errors={formik.errors.email}
                  />
                </InputAdornment>
              ),
            }}
          />
          <PasswordField
            name="password"
            variant="outlined"
            value={formik.values.password}
            label={
              formik.touched.password
                ? formik.errors.password || formatMessage('login_password')
                : null
            }
            InputLabelProps={{ shrink: true }}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            placeholder={formatMessage('login_password')}
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <FormIcon
                    icon={KeyIcon}
                    aria-label="password"
                    isTouched={formik.touched.password}
                    errors={formik.errors.password}
                  />
                </InputAdornment>
              ),
            }}
          />
          <FormControlLabel
            label={formatMessage('login_showPassword')}
            control={
              <Checkbox
                name="showPassword"
                checked={Boolean(showPassword)}
                onChange={() => setShowPassword(!showPassword)}
                color="primary"
              />
            }
          />
          <LoginButton
            variant="contained"
            color="secondary"
            disabled={!(formik.isValid && formik.dirty)}
            type="submit">
            {formatMessage('login_login')}
          </LoginButton>
        </form>

        <InfoDialog
          open={responseState === WRONG_CREDENTIALS_STATE}
          onClose={closeHandler}
          title={formatMessage('login_wrongCredentialsHead')}
          text={formatMessage('login_wrongCredentialsText')}
        />

        <InfoDialog
          open={responseState === FAILURE_STATE}
          onClose={closeHandler}
          text={formatMessage('something_went_wrong')}
        />
      </AuthContainer>
    </AuthLayout>
  );
};

export default Login;
