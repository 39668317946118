import { memo } from 'react';
import { useAssetListData } from 'hooks/useAssetListData';
import { useCompanyListData } from 'hooks/useCompanyListData';

const GraphQlWrapper = (props) => {
  useAssetListData();
  useCompanyListData();

  return props.children;
}

export default memo(GraphQlWrapper);
