import React from 'react';
import TextField from '@material-ui/core/TextField';
import { FormattedMessage } from 'react-intl';
import Chip from 'components/Chip/Chip';
import Autocomplete from 'components/Autocomplete/Autocomplete';
import { Availability } from 'helpers/asset';
import { Option, OptionIcon, ChipIcon } from './style';

const allStatusOption = { value: true, label: 'all_availabilities', };

const options = Object.values(Availability).map(option => ({
  value: option.value,
  label: option.name,
}));

function getOptionsByValues(values) {
  if (values.length === 0) {
    return [allStatusOption];
  }

  return values
    .filter(option => option.value !== true)
    .map(value => options.find(option => option.value === value));
}

function renderTags(value, getTagProps) {
  const tags = value.map((option, index) => (
    <Chip
      icon={<ChipIcon availability={option.value} />}
      label={<FormattedMessage id={option.label} />}
      {...getTagProps({ index })}
    />
  ));

  return tags;
}

function AvailabilityField({ onChange, value }) {
  const valueOptions = getOptionsByValues(value);

  const changeHandler = (event, selectedOptions) => {
    onChange(event, selectedOptions.filter(option => option.value !== true).map(option => option.value))
  }

  return (<>
    <Autocomplete
      value={valueOptions}
      onChange={changeHandler}
      options={options}
      multiple
      getOptionLabel={(option) => option.label}
      getOptionSelected={(option, value) => option.value === value.value}
      renderInput={(params) => <TextField {...params} label={<FormattedMessage id="filter_availability" />}
      InputLabelProps={{ shrink: true }} variant="outlined" />}
      renderOption={(option) => (<Option><OptionIcon availability={option.value} /><FormattedMessage id={option.label} /></Option>)}
      renderTags={renderTags}
    /></>
  );
};

AvailabilityField.defaultProps = {
  onChange: () => null,
};

export default AvailabilityField;
