import { configureStore } from '@reduxjs/toolkit';
import throttle from 'lodash/throttle';
import { appSlice } from './slices/appSlice';
import { authenticationSlice } from './slices/authenticationSlice';
import { userSlice } from './slices/userSlice';
import { geocoderSlice } from './slices/geocoderSlice';
import { assetSlice } from './slices/assetSlice';
import { saveState, loadState } from './cache';

const store = configureStore({
  reducer: {
    app: appSlice.reducer,
    user: userSlice.reducer,
    asset: assetSlice.reducer,
    geocoder: geocoderSlice.reducer,
    authentication: authenticationSlice.reducer,
  },
  preloadedState: loadState(),
});


store.subscribe(throttle(() => saveState(store.getState()), 1000));

export default store;