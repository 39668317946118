import { withStyles, styled } from '@material-ui/styles';
import MuiTypography from '@material-ui/core/Typography';
import AssetStatusIcon from 'components/AssetStatusIcon/AssetStatusIcon';

export const Container = styled('div')(({
  display: 'flex',
  gap: 16,
  padding: '1px 0',
  '& + &': {
    borderTop: '1px solid #d8d8d8',
  }
}));

export const ImageContainer = styled('div')({
  position: 'relative',
});

export const Content = styled('div')({
  flex: 1,
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
});

const commonSubtitleStyle = {
  fontSize: 14,
  lineHeight: 1.43,
  letterSpacing: '0.15px',
}

export const Typography = withStyles((theme) => ({
  subtitle1: {
    ...commonSubtitleStyle,
    color: 'rgba(0, 0, 0, 0.54)'
  },
  subtitle2: {
    ...commonSubtitleStyle,
    color: 'rgba(0, 0, 0, 0.87)',
  }
}))(MuiTypography);

export const AvailabilityAndStatusIcon = styled(AssetStatusIcon)(({ theme }) => ({
  marginRight: theme.spacing(1),
  position: 'absolute',
  left: '100%',
  transform: 'translateX(-50%)',
  top: 0,
  zIndex: 9999,
}));
