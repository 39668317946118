import { useEffect, useRef } from 'react';

function ClickOutside({ onClickOutside, ignoreElement, ...restProps }) {
  const wrapperRef = useRef(null);

  useEffect(() => {
    function clickHandler(event) {
      if (!wrapperRef.current?.contains(event.target) && !ignoreElement?.contains(event.target)) {
        onClickOutside(event);
      }
    }

    document.addEventListener('mousedown', clickHandler);

    return () => {
      document.removeEventListener('mousedown', clickHandler);
    }
  }, [onClickOutside, ignoreElement]);

  return (
    <div ref={wrapperRef} {...restProps} />
  );
}

export default ClickOutside;
