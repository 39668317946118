import Base from './Base';
import Image from './Image';

const schema = {
  createdAt: 'cA',
  id: 'id',
  image: 'i1',
  isNew: 'iN',
  reportedAt: 'rA',
  deletedAt: 'da',
  transactionCheckValue: 'tCV1',
  typeName: '__typename',
  updatedAt: 'uA',
};

export default class CheckValueImage extends Base {
  static schema = schema;
  static typeName = 'CVI';

  constructor(properties = {}) {
    super(properties);
    this.image = new Image(this.image);
  }
}
