import { FormattedMessage } from 'react-intl';
import LogoUrl from './assets/logo.png';
import { BrandText, Wrapper } from './style';

function Logo() {
  return (
    <Wrapper>
      <img src={LogoUrl} alt="klickrent" width="46px" height="46px" />
      <FormattedMessage id="header" values={{ br: <br /> }} tagName={BrandText} />
    </Wrapper>
  )
}

export default Logo;
