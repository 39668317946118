import React from 'react';
import Logo from 'components/Logo/Logo';
import { Content, Drawer, Header } from './style';

function Sidebar({ children }) {
  return (
    <Drawer
      variant="persistent"
      open={true}
      PaperProps={{
        elevation: 16
      }}>
      <Header>
        <Logo />
      </Header>

      <Content>
        {children}
      </Content>
    </Drawer>
  );
}

export default Sidebar;
