import { gql } from '@apollo/client';

export const GET_COMPANIES_PAGINATED = gql`
  query GetCompaniesPaginated($after: String) {
    getC2P(after: $after, first: 100, orderBy: { column: NAME, order: ASC }) {
      edges {
        node {
          b2(orderBy: { column: POSTCODE, order: ASC }) {
            c
            cC
            id
            n
            p
          }
          cL2(orderBy: { column: NAME, order: ASC }) {
            id
            n
          }
          id
          n
          e
          cA
          aBC
          aAAC
        }
      }
      pageInfo {
        hasNextPage
        endCursor
        total
      }
    }
  }
`;
