import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from './style';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
} from '@material-ui/core';

const InfoDialog = ({ open, onClose, title, text }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      {title && (
        <DialogTitle disableTypography>
          <Typography variant="h6">{title}</Typography>
          {onClose ? (
            <IconButton aria-label="close" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          ) : null}
        </DialogTitle>
      )}
      <DialogContent dividers>{text}</DialogContent>
    </Dialog>
  );
};

InfoDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  text: PropTypes.string,
};

export default InfoDialog;
