import { ApolloClient, from, HttpLink, InMemoryCache } from '@apollo/client';
import { relayStylePagination } from '@apollo/client/utilities';
import { CachePersistor } from 'apollo3-cache-persist';
import Asset from 'entities/Asset';
import GuidEntry from 'entities/GuidEntry';
import * as localforage from 'localforage';
import { GET_ASSETS_PAGINATED, GET_COMPANIES_PAGINATED } from './endpoints';
import { GRAPHQL_URL } from 'helpers/config';
import authLink from './links/auth';

const SCHEMA_VERSION = '1';
const SCHEMA_VERSION_KEY = 'kam-schema-version';

const cache = new InMemoryCache({
  typePolicies: {
    [Asset.typeName]: {
      fields: {
        clientId: {
          read: (_, { readField }) => readField('g', readField('fGE1')),
        },
      },
    },
    [GuidEntry.typeName]: {
      keyFields: [GuidEntry.schema.guid],
    },
    Query: {
      fields: {
        [GET_ASSETS_PAGINATED]: relayStylePagination(),
        [GET_COMPANIES_PAGINATED]: relayStylePagination(),
      },
    },
  },
});

const persistor = new CachePersistor({
  cache: cache,
  debug: true,
  key: 'persisted-kam-cache',
  maxSize: false,
  serialize: false,
  storage: localforage,
});

const client = new ApolloClient({ cache: cache });

async function clientResolver() {
  try {
    const currentVersion = await localforage.getItem(SCHEMA_VERSION_KEY);

    if (currentVersion === SCHEMA_VERSION) {
      await persistor.restore();
    } else {
      await persistor.purge();
      await localforage.setItem(SCHEMA_VERSION_KEY, SCHEMA_VERSION);
    }

    const link = from([
      authLink,
      new HttpLink({
        uri: GRAPHQL_URL,
      }),
    ]);

    client.setLink(link);

    return client;
  } catch (err) {
    console.error('An error has occured while instantiating Apollo client');
  }
}

export default clientResolver;
