import { createSlice } from '@reduxjs/toolkit';

export const authenticationSlice = createSlice({
  name: 'authentication',
  initialState: {
    isLoggedIn: false,
    token: null,
  },
  reducers: {
    loggedIn: (state) => {
      state.isLoggedIn = true;
    },
    logout: (state) => {
      state.isLoggedIn = false;
      state.token = null;
    },
    setToken(state, action) {
      state.token = action.payload;
    },
  },
});

export const {
  loggedIn,
  logout,
  resetToken,
  setToken,
} = authenticationSlice.actions;
