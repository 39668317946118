import Base from './Base';

const schema = {
  city: 'c',
  company: 'c1',
  cost_center: 'cC',
  createdAt: 'cA',
  email: 'e',
  id: 'id',
  name: 'n',
  postcode: 'p',
  status: 's',
  streetAndNumber: 'sN',
  typeName: '__typename',
  updatedAt: 'uA',
};

export default class Branch extends Base {
  static schema = schema;
  static typeName = 'AT';

  getName() {
    return this.constructor.getBranchName(this);
  }

  static getBranchName(branch) {
    if (branch === null) return '';
    const { postcode, city, cost_center, costCenter } = branch;
    const nameParts = [];

    if (postcode) nameParts.push(postcode);
    if (city) nameParts.push(city);
    if (cost_center) nameParts.push('(' + cost_center + ')');
    if (costCenter) nameParts.push('(' + costCenter + ')');

    return nameParts.join(' ');
  }

  static getBranchFrom(branches, branchId) {
    if (!Array.isArray(branches)) return null;
    return branches.find((branch) => branch?.id === branchId);
  }
}
