// https://stackoverflow.com/a/33946793/2324048
function rateLimiterFactory(duration = 1000, limit = 50) {
  let time = 0;
  let count = 0;
  let difference = 0;
  const queue = [];

  return async function process(func) {
    if (func) queue.push(func);

    difference = duration - (window.performance.now() - time);

    if (difference <= 0) {
      time = window.performance.now();
      count = 0;
    }

    if ((count + 1) <= limit) {
      const queuedProcess = queue.shift();

      count++;

      await queuedProcess({ isLast: queue.length === 0 });
    } else {
      setTimeout(process, difference);
    }
  };
};

export default rateLimiterFactory;
