import { withStyles } from '@material-ui/styles';
import MuiPopover from '@material-ui/core/Popover';
import IconButton from '@material-ui/core/IconButton';

export const Popover = withStyles((theme) => ({
  paper: {
    borderRadius: theme.spacing(1.5),
    padding: theme.spacing(5, 3, 5, 6),
    width: 380,
    maxWidth: '100%',
  }
}))(MuiPopover);

export const CloseButton = withStyles((theme) => ({
  root: {
    position: 'absolute',
    right: theme.spacing(0.5),
    top: theme.spacing(0.5),
    color: '#303030',
  }
}))(IconButton);
