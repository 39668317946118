import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  branches: {},
};

export const geocoderSlice = createSlice({
  name: 'geocoder',
  initialState: INITIAL_STATE,
  reducers: {
    setBranch(state, action) {
      const { payload } = action;
      state.branches[payload.id] = payload.coords;
    },
    setBranches(state, action) {
      const { payload } = action;
      state.branches = {
        ...state.branches,
        ...payload,
      };
    },
    resetBranches(state) {
      state.branches = {};
    }
  },
});

export const { setBranches } = geocoderSlice.actions;
